module.exports = [{
      plugin: require('/home/chileno/deploy/chileno-index/node_modules/gatsby-plugin-layout/gatsby-browser'),
      options: {"plugins":[],"component":"/home/chileno/deploy/chileno-index/src/layouts/index.js"},
    },{
      plugin: require('/home/chileno/deploy/chileno-index/node_modules/gatsby-plugin-catch-links/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/chileno/deploy/chileno-index/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/chileno/deploy/chileno-index/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/chileno/deploy/chileno-index/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
